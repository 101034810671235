.Flex {
  display: flex;
  height: calc(100% - 100px);
  align-items: center;
}
@media (orientation: landscape) and (max-height: 600px) {
  .Flex {
    height: auto;
    min-height: 370px;
  }
}
@media (orientation: landscape) and (min-width: 768px) and (max-width: 1023px) {
  .Flex {
    min-height: 300px;
  }
}

.box {
  padding-top: 56.2162162162%;
  position: relative;
  background-color: #000;
  overflow: hidden;
  box-shadow: 0px 1px 2px 0px rgba(60, 64, 67, 0.3), 0px 1px 3px 1px rgba(60, 64, 67, 0.15);
  min-height: 300px;
  border-radius: 6px;
}
.box > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.box [class*=record-button__Instructions] {
  background: rgba(255, 255, 255, 0.9);
  padding: 6px;
  border-radius: 2px;
  margin-bottom: 10px;
  color: #000;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.row {
  display: flex;
  padding: 10px 15px;
  margin: 0 auto;
  align-items: center;
}

.colLeft {
  flex: 0 0 50%;
  text-align: left;
}
.colRight {
  flex: 0 0 50%;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.logo {
  max-width: 100px;
  max-height: 30px;
}
@media (min-width: 768px) {
  .logo {
    max-width: 150px;
    max-height: 60px;
  }
}
@media (orientation: landscape) and (max-width: 849px) {
  .logo {
    max-width: 100px;
    max-height: 30px;
  }
}

.userIcon {
  padding: 5px 6px;
  background: #000;
  border-radius: 50%;
  border: 1px solid #000;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.userIcon svg {
  width: 15px;
}

.examType {
  font-size: 10px;
  display: block;
}

button {
  cursor: pointer;
}

.InnerBox {
  position: relative;
}

.ChecksInfo {
  display: inline-flex;
  align-items: center;
  padding: 5px 14px;
  border-radius: 16px;
  border: 1px solid #dadce0;
  font-size: 0.875rem;
  letter-spacing: 0.0178571429em;
  font-weight: 500;
}
.ChecksInfo svg {
  width: 20px;
  margin-right: 10px;
}

.Bar {
  padding-bottom: 20px;
  padding-top: 20px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}
@media (orientation: landscape) and (max-width: 767px) {
  .Bar {
    position: relative;
  }
}