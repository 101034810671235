*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

html, body {
  height: 100%;
}

.container {
  max-width: 1024px;
  margin: 0 auto;
}

.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.btn {
  text-decoration: none;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 3px;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-primary {
  color: #000D21;
  background-color: #fff;
  font-size: 18px;
  padding: 12px 18px;
  text-transform: uppercase;
  font-family: "Rubik", sans-serif;
}

.btn-primary:hover {
  color: #fff;
  background-color: #04dbec;
}

.btn-white {
  color: #fff;
  border-color: #000;
  background: #000;
  padding: 10px 50px;
  font-family: "Rubik", sans-serif;
  letter-spacing: 0.25px;
  line-height: 36px;
  font-size: 20px;
}
@media (min-width: 768px) {
  .btn-white {
    padding: 10px 33px;
    line-height: 20px;
    font-size: 16px;
  }
}

.round {
  border-radius: 25px;
}

.b-500 {
  font-weight: 700;
}

.full-width {
  width: 100%;
}

.text-center {
  text-align: center;
}

.text-white {
  color: #fff;
}

.systemCheck #root,
.systemCheck .App,
.interviewPage .App,
.interviewPage #root,
.systemCheck .App > div {
  height: 100%;
}

.interviewPage {
  background: #202124;
}

.icon-space {
  margin-right: 0.25rem;
}