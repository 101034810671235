.overflow, .skChaseDot {
  width: 100%;
  height: 100%;
}

.overflow, .skChaseDot, .skChase {
  left: 0;
  top: 0;
}

.skChase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
  position: fixed;
  z-index: 99;
  right: 0;
  margin: auto;
  bottom: 0;
}

.skChaseDot {
  position: absolute;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}

.skChaseDot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.skChaseDot:nth-child(1) {
  animation-delay: -1.1s;
}

.skChaseDot:nth-child(2) {
  animation-delay: -1s;
}

.skChaseDot:nth-child(3) {
  animation-delay: -0.9s;
}

.skChaseDot:nth-child(4) {
  animation-delay: -0.8s;
}

.skChaseDot:nth-child(5) {
  animation-delay: -0.7s;
}

.skChaseDot:nth-child(6) {
  animation-delay: -0.6s;
}

.skChaseDot:nth-child(1):before {
  animation-delay: -1.1s;
}

.skChaseDot:nth-child(2):before {
  animation-delay: -1s;
}

.skChaseDot:nth-child(3):before {
  animation-delay: -0.9s;
}

.skChaseDot:nth-child(4):before {
  animation-delay: -0.8s;
}

.skChaseDot:nth-child(5):before {
  animation-delay: -0.7s;
}

.skChaseDot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot {
  80%, 100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%, 0% {
    transform: scale(1);
  }
}
.overflow {
  background-color: #000;
  opacity: 0.85;
  position: fixed;
  z-index: 98;
}