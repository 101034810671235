@keyframes scaling {
  0%, 100% {
    transform: scale(0.5);
  }
  40% {
    transform: scale(1);
  }
  50% {
    transform: scale(1);
  }
}
.Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  width: 100%;
  margin: auto;
  padding-left: 25px;
  padding-right: 25px;
}

.Left {
  text-align: left;
  font-size: 27px;
  color: #70757a;
}

.Right {
  text-align: right;
  flex: 1;
}

.MicText {
  opacity: 0;
  transition: all 200ms;
  display: inline-block;
}

.TextActive {
  opacity: 1;
  transition: all 200ms;
}

.Dots {
  display: inline-block;
  opacity: 0;
  transition: all 200ms;
}

.DotsActive {
  opacity: 1;
  transition: all 200ms;
}

.Dot {
  display: inline-block;
  background-color: #70757a;
  width: 5px;
  height: 5px;
  margin: 0 4px;
  border-radius: 50%;
  animation: scaling 2.5s ease-in-out infinite;
}
.Dot:nth-child(0) {
  animation-delay: 0s;
}
.Dot:nth-child(1) {
  animation-delay: 0.2s;
}
.Dot:nth-child(2) {
  animation-delay: 0.4s;
}
.Dot:nth-child(3) {
  animation-delay: 0.6s;
}
.Dot:nth-child(4) {
  animation-delay: 0.8s;
}
.Dot:nth-child(5) {
  animation-delay: 1s;
}

.MicContainer {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  position: relative;
  z-index: 1;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.MicContainer > svg {
  width: 25px;
}
@media (min-width: 1024px) {
  .MicContainer > svg {
    width: 84px;
  }
}
.MicContainer > svg > path {
  transition: all 0.218s;
}

.MicWrap {
  position: relative;
  z-index: 0;
  width: 60px;
  height: 60px;
  margin: auto;
}
@media (min-width: 1024px) {
  .MicWrap {
    width: 164px;
    height: 164px;
  }
}

.MicBg {
  position: absolute;
  background-color: #3d4143;
  transform: scale(0.01);
  transition: opacity 0.218s;
  width: 100px;
  height: 100px;
  z-index: -1;
  left: -20px;
  top: -20px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 1024px) {
  .MicBg {
    width: 230px;
    height: 230px;
    left: -33px;
    top: -31px;
  }
}

.MicBgActive {
  transform: scale(1);
}