.body {
  padding: 0 15px;
}
.bodyQuestion {
  text-align: center;
}
.bodyQuestion h1 {
  font-weight: 500;
}
.bodyAnswers {
  width: 100%;
}

.Answer {
  display: inline-block;
  border-radius: 20px;
  border: 1px solid #000000;
  padding: 10px 20px;
  font-size: 18px;
}
.AnswerContainer {
  width: 100%;
  margin-top: 25px;
  text-align: center;
}

.bottom {
  padding: 15px;
  text-align: right;
}

.nextButton {
  border-radius: 20px;
  border: 1px solid #3d4143;
  padding: 10px 20px;
  width: 100%;
  background: #fff;
  color: #3d4143;
  font-weight: 700;
  cursor: pointer;
}
.nextButton:disabled {
  opacity: 0.65;
  pointer-events: none;
}
@media (min-width: 768px) {
  .nextButton {
    max-width: 150px;
  }
}

.box {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .box {
    align-items: center;
  }
}
.box > div {
  flex: 0 0 100%;
}
@media (min-width: 768px) {
  .box .body {
    flex: 0 0 calc(100% - 200px);
  }
}
@media (min-width: 768px) {
  .box .bottom {
    min-width: 200px;
    flex: 0 0 200px;
  }
}

.desktop {
  display: none;
}
@media (min-width: 768px) {
  .desktop {
    display: block;
  }
}

@media (min-width: 768px) {
  .mobile {
    display: none;
  }
}